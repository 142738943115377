import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseUrl } from "../../baseurl";
import Newpopup from "./Newpopup";



const Page5 = () => {

    const [formData, setFormData] = useState({
        EMAIL: "",
        FNAME: "",
        LNAME: "",
        PHONE: "",
        tags: "5457830", // Adding the hidden tag
      });
    
      const [responseMessage, setResponseMessage] = useState("");
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };

      const [firstName, setFirstName] = useState("");
      const [lastName, setLastName] = useState("");
      const [email, setEmail] = useState("");
      const [country, setCountry] = useState("");
      const [phoneNumber, setPhoneNumber] = useState("");
      const [reason, setReason] = useState("");
      const [details, setDetails] = useState("");

      const [showpop,setshowpop]=useState(false)
    
    
         
      const handleSubmit = async () => {
       

        const formData={

            EMAIL: email,
            FNAME: firstName,
            LNAME: lastName,
            PHONE: phoneNumber,
            tags: "5457830",


        }

    
        const mailchimpUrl = `https://ownapieceofcolorado.us8.list-manage.com/subscribe/post?u=343c895cb18c7bd146370ef82&id=e0c1940a12`;
    
        const form = new URLSearchParams(formData);
    
        try {
          const response = await fetch(mailchimpUrl, {
            method: "POST",
            body: form,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            mode: "no-cors", // This allows the request to bypass CORS restrictions
          });
    
          if (response.ok) {
            // setMessage("Subscription successful!");
            console.log("ok")
        
          } else {
            // setMessage("Subscription failed. Please try again.");
            console.log("ok !!!")
            handleSubmit1()
          }
        } catch (error) {
          console.error("Error submitting data:", error);
          // setMessage("An error occurred while subscribing.");
        }
      };


  const handleSubmit1 = async () => {



        
        try {

      
            const formData={
                firstName,
                lastName,
                email,
                country,
                phoneNumber,
                reason,
                details


            }



          const response = await axios.post(
            `${baseUrl}/subscribe`, // Replace with your server endpoint
            formData
          );


          console.log(response.data.success,"T")

          if(response.data.success==true)
          {
            setshowpop(true)

            setTimeout(() => {
              window.location.reload(); // Reloads the current page
              window.parent.location.href = 'https://www.kosmosresort.com/'; 
            }, 2000);
          }


           
        } catch (error) {
          console.error("Error submitting the form", error);
       
        }
      };




    return (
        <div className="main-web-sec">

{
showpop &&
      <Newpopup/>
}

        <div className="containerx">
          <div className="main-web-5">
            <div className="web-5-heading">
              <h2>We’d love to hear from you!</h2>
              {/* <p>Our teams are delighted to engage with you.</p> */}
            </div>
  
            <div className="all-main-web">
              <div className="web-Personal-box">
                <h2>Personal Information</h2>
                <div className="Personal-box-input">
                  <input
                    type="text"
                    placeholder="First Name *"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Last Name *"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <input
                    type="email"
                    placeholder="Email Address *"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <select
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    <option value="" disabled hidden>
                      Country *
                    </option>
                    <option value="USA">USA</option>
                    {/* <option value="Option 2">Option 2</option>
                    <option value="Option 3">Option 3</option> */}
                  </select>
                  <input
                    type="number"
                    placeholder="Phone Number *"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>
  
              <div className="web-Personal-box">
                <h2>Reason for Inquiry</h2>
                <div className="Personal-box-input">
                  <select
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  >
                    <option value="" disabled hidden>
                      Select your reason for the inquiry *
                    </option>
                    <option value="Wedding Inquiry">Wedding Inquiry</option>
                    <option value="Proposal Inquiry">Proposal Inquiry</option>
                    <option value="Reserving Entire Resort">Reserving Entire Resort</option>
                    <option value="Educational Outing">Educational Outing</option>
                    <option value="General Event Inquiry">General Event Inquiry</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
  
              <div className="web-Personal-box">
                <div className="Personal-box-input">
                  <div className="extara-div">
                    <label htmlFor="">Details</label>
                    <textarea
                      placeholder=""
                      value={details}
                      onChange={(e) => setDetails(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
  
              <div className="web-Personal-box">
                <h2>Contact Us</h2>
                <div className="Personal-box-input">
                  <div className="extara-div">
                    <label htmlFor="">Call</label>
                    <input type="text" placeholder="719-496-0180" readOnly />
                  </div>
  
                  <div className="extara-div">
                    <label htmlFor="">Email</label>
                    <input type="text" placeholder="hello@kosmosresort.com" readOnly />
                  </div>
                </div>
              </div>
  
              <button onClick={() => handleSubmit()}>
                Send Request
              </button>
            </div>
          </div>
        </div>
      </div>
    )

}


export default Page5