
import './App.css';

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from './pages/landing';
import Popup from './pages/popup';
import Projects from './pages/projects';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from './pages/PaymentForm';
import My1 from './pages/My1';
import My2 from './pages/My2';
import My3 from './pages/My3';
import My4 from './pages/My4';
import Page3 from './components/landing-page-componets/Page3';
import E1 from './pages/E1';
import E4 from './pages/E4';
import E3 from './pages/E3';
import E2 from './pages/E2';
import ContactUs from './pages/ContactUs';
import Page5 from './components/landing-page-componets/Page5';
import Newpopup from './components/landing-page-componets/Newpopup';


const stripePromise = loadStripe('pk_live_51QADyJP9FYwrC42UyWucXyn4sH0nZmoNIqeAtFZrrGzrKs5469Kb6x5pvuw28arjVf0Lc64cmv6qRkGyNvvUD2co00Bapf1tqN'); 

function App() {
  return (
    <Router>
    <Routes>

   
    {/* <Route
  path="/"
  element={
    <Elements stripe={stripePromise}>
      <PaymentForm />
    </Elements>

        
  }
/>
 */}

<Route path="/" exact element={<My1/>}/>
<Route path="/English" exact element={<E1/>}/>

<Route
  path="/paymentprocess"
  element={
    <Elements stripe={stripePromise}>
      <My3 />
    </Elements>

        
  }
/>

<Route
  path="/engpaymentprocess"
  element={
    <Elements stripe={stripePromise}>
      <E3 />
    </Elements>

        
  }
/>

<Route path="/contactus" exact element={<ContactUs/>}/>

<Route path="/From2" exact element={<My2/>}/>
<Route path="/engform2" exact element={<E2/>}/>


<Route path="/From3" exact element={<My3/>}/>
<Route path="/engform3" exact element={<E3/>}/>


<Route path="/From4" exact element={<My4/>}/>
<Route path="/engform4" exact element={<E4/>}/>


<Route path="/Page5" exact element={<Page5/>}/>



<Route path="/Newpopup" exact element={<Newpopup/>}/>
      </Routes>


      
      </Router> 

  //   <Elements stripe={stripePromise}>
  //   <PaymentForm />
  // </Elements>

 



  );
}

export default App;
