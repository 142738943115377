import React, { useEffect, useState } from 'react';

import Page3 from '../components/landing-page-componets/Page3';
import Footer from '../components/Header_Footer/Footer';
import Page3_Spanish from '../components/landing-page-componets/Page3_Spanish';


  

const E3 = () => {

 

  return (

    <>
    <Page3/>
    

    </>
  )

}

export default E3