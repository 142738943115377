import React, { useEffect, useState } from 'react';
import Footer from '../components/Header_Footer/Footer';
import Page2_Spanish from '../components/landing-page-componets/Page2_Spanish';
import Page2 from '../components/landing-page-componets/Page2';


  

const E2 = () => {

 

  return (

    <>
    <Page2/>
    
    </>
  )

}

export default E2