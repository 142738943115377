import React, { useState } from "react";

import Conference from "../../img/Conference.png";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Page1_Spanish = () => {


    const [count, setCount] = useState(); 

    const navigate = useNavigate();

    const SetPricing = async (e) => {

        console.log(e)

        localStorage.setItem('pricing', e);
        setCount(e)


    }


    const gotonewpage = () => {

        if (!count) {
            toast.error("You must select a price before proceeding.");
            return;
        }



        navigate('/From2');

    }

    return (
        <>

        <ToastContainer/>
       {/* <div className="Hero--Section">
        <img src={Conference}/>
    </div> */}

    
    <div className="container">


    <div className="Main--section">
        <div className="Main--container">

            <div className="Main--section--first">
                <p>Asegura Tu Lugar</p>


                <h2>Regístrate para nuestra Conferencia</h2>
                <h6>Su registro e este evento le dará derecho a participar en dos conferencias en el Sheraton Mexico City Maria Isabel:
                </h6>

                <ul>
                    <li><span>Diciembre 9: </span>
                    ¿Por qué invertir en los Estados Unidos, y ¿Cuáles son los beneficios de invertir en los estados de Florida y Colorado?.
                    </li>
                    <li>¿Cuál es el proceso para que un inversionista extranjero compre bienes raíces de una manera correcta y segura en USA?</li>
                    <li>
                    ¿Cuál es el proceso para que un inversionista extranjero compre bienes raíces de una manera correcta y segura en USA?
                    </li>
                    <li> <span>Diciembre 10: </span>Opciones de inversión en proyectos de pre-construcción en el sureste de la Florida y Opciones de inversión en tierra en el sur del estado de Colorado.</li>


                        <li>Endremos consultas personalizadas disponibles durante tres días (Diciembre 9,10 & 11), desde las 11:00 de la mañana a las 6:00 de la tarde.</li>
                </ul>
            </div>



            <div className="heelo-2">
            <div className="Main--section--last">
                <div className="Main--section--last--main">

                    <div className="Main--section--last--part1">
                        <h3>Experiencia en persona</h3>

                        <div className="Main--section--last--part1--box"  onClick={() => SetPricing(30)}
                              style={{
                                background: count === 30 ? "grey" : "transparent",
                                color: count === 30 ? "white" : "black",
                              }}
                            >
                            <h4   
                            style={{
                                color: count === 30 ? "white" : "black",
                              }}
                              >$30</h4>
                            <h5>Precio anticipado <span> (precio habitual $50) </span> </h5>

                            <ul>
                                <li
                                 style={{
                                color: count === 30 ? "white" : "black",
                              }}
                                >✓ Acceso completo a la conferencia</li>
                                <li
                                 style={{
                                color: count === 30 ? "white" : "black",
                              }}
                                >✓ Consulta privada</li>
                                <li
                                 style={{
                                color: count === 30 ? "white" : "black",
                              }}
                                >✓ Desayuno de networking</li>
                                <li
                                 style={{
                                color: count === 30 ? "white" : "black",
                              }}
                                >✓ Acceso directo a expertos</li>

                            </ul>
                        </div>

                    </div>
                    <div className="Main--section--last--part1">

                        <h3>Lo que Aprenderás</h3>

                        <div className="Main--section--last--part1--box flex-box">

                            <ul>
                                <li>Análisis de Mercado</li>
                                <li>ROI Estrategias</li>
                                <li>Marco Legal</li>
                                <li> Planificación Fiscal</li>

                            </ul>
                            <ul>
                                <li>Selección de Propiedades</li>
                                <li>Tendencias del Mercado</li>
                                <li>Gestión de Riesgos</li>
                                <li>Estrategias de Salida</li>

                            </ul>
                        </div>

                    </div>

                </div>

                <div className="Main--section--last--main">

                    <div className="Main--section--last--part1">
                        <h3>Asistencia virtual</h3>

                        <div className="Main--section--last--part1--box" onClick={() => SetPricing(10)}
                            style={{
                                background: count === 10 ? "grey" : "transparent",
                                color: count === 10 ? "white" : "black",
                              }}
                            >
                            <h4
                              style={{
                                color: count === 10 ? "white" : "black",
                              }}
                            >$10</h4>
                            <h5>Acceso en Línea</h5>

                            <ul>    
                                <li
                                  style={{
                                color: count === 10 ? "white" : "black",
                              }}
                                >✓  Acceso a transmisión en vivo</li>
                                <li
                                  style={{
                                color: count === 10 ? "white" : "black",
                              }}
                                >✓   Sesión de preguntas y respuestas en línea</li>
                                <li
                                  style={{
                                color: count === 10 ? "white" : "black",
                              }}
                                >✓ Materiales digitales</li>
                                <li
                                  style={{
                                color: count === 10 ? "white" : "black",
                              }}
                                >✓  Grabaciones de sesiones</li>

                            </ul>
                        </div>

                    </div>
                    <div className="Main--section--last--part1">

                        <h3>¿Por qué el Grupo Zalaquett?</h3>

                        <div className="Main--section--last--part1--box">

                            <ul>
                                <li>Más de 50 inversionistas internacionales exitosos</li>
                                <li> Soluciones completas de inversión de principio a fin</li>
                                <li>Asesoramiento experto en mercados inmobiliarios de EE. UU</li>
                                <li> Especializada en ayudar a inversionistas latinoamericanos.</li>

                            </ul>
                           
                        </div>

                    </div>


                </div>

                
             


            </div>


            
                
            <div className="Main--section--last--part1--button" onClick={gotonewpage}>
            Reserva Ahora {count ? `$${count}` : ''}
            </div>

        </div>

    
        </div>
    </div>

</div>
        </>
    )



}


export default Page1_Spanish