import React, { useState } from "react";
import { useNavigate } from "react-router-dom";


import Conference from "../../img/Conference.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Page1 = () => {

    const [count, setCount] = useState(); 

    const navigate = useNavigate();

    const SetPricing = async (e) => {

        console.log(e)

        localStorage.setItem('pricing', e);
        setCount(e)

    }


    const gotonewpage = () => {

        if (!count) {
            toast.error("You must select a price before proceeding.");
            return;
        }



        navigate('/engform2');

    }
    return (
        <>
        <ToastContainer/>

       <div className="Hero--Section">
        {/* <img src={Conference}/> */}
    </div>

    
    <div className="container">


    <div className="Main--section">
        <div className="Main--container">

            <div className="Main--section--first">
                <p>Secure Your Spot</p>


                <h2>Register for our Conference</h2>
                <h6>Your registration for this event will give you the right to participate in two conferences at the Sheraton Mexico City Maria Isabel:
                </h6>

                <ul>
                    <li><span>December 9: </span>
                    Why invest in the United States, and what are the benefits of investing in      the states of Florida and Colorado?.
                    </li>
                    <li>What is the process for a foreign investor to purchase real estate correctly and safely in the USA?
                        </li>
                    <li>
                    What is the process for a foreign investor to purchase real estate correctly and safely in the USA?
                    </li>
                    <li> <span>December 10: </span>We will have personal Opciones de inversión en proyectos de pre-construcción en el sureste de la Florida y Opciones de inversión en tierra en el sur del estado de Colorado.ized consultations available for three days (December 9, 10 & 11), from
                        11:00 in the morning to 6:00 in the afternoon.</li>


                        <li>Endremos consultas personalizadas disponibles durante tres días (Diciembre 9,10 & 11), desde las 11:00 de la mañana a las 6:00 de la tarde.</li>
                </ul>
            </div>



            <div className="heelo-2">
            <div className="Main--section--last">
                <div className="Main--section--last--main">

                    <div className="Main--section--last--part1">
                        <h3>In-Person Experience</h3>

                        <div className="Main--section--last--part1--box"  onClick={() => SetPricing(30)}
                              style={{
                                background: count === 30 ? "grey" : "transparent",
                                color: count === 30 ? "white" : "black",
                              }}
                            >
                            <h4
                            style={{
                                color: count === 30 ? "white" : "black",
                              }}

                            >$30</h4>
                            <h5>Early Bird Price <span> (Regular $50) </span> </h5>

                            <ul>
                                <li
                                  style={{
                                color: count === 30 ? "white" : "black",
                              }}
                                >✓ Full Conference Access</li>
                                <li
                                  style={{
                                color: count === 30 ? "white" : "black",
                              }}
                                >✓ Private Consultation</li>
                                <li
                                  style={{
                                color: count === 30 ? "white" : "black",
                              }}
                                >✓ Networking Breakfast</li>
                                <li
                                  style={{
                                color: count === 30 ? "white" : "black",
                              }}
                                >✓ Direct Expert Access</li>

                            </ul>
                        </div>

                    </div>
                    <div className="Main--section--last--part1">

                        <h3>What You'll Learn</h3>

                        <div className="Main--section--last--part1--box flex-box">

                            <ul>
                                <li>Market Analysis</li>
                                <li> ROI Strategies</li>
                                <li>Legal Framework</li>
                                <li> Tax Planning</li>

                            </ul>
                            <ul>
                                <li>Property Selection</li>
                                <li>Market Trends</li>
                                <li>Risk Management</li>
                                <li>Exit Strategies</li>

                            </ul>
                        </div>

                    </div>

                </div>

                <div className="Main--section--last--main">

                    <div className="Main--section--last--part1">
                        <h3>Virtual Attendance</h3>

                        <div className="Main--section--last--part1--box" onClick={() => SetPricing(10)}
                            
                            style={{
                                background: count === 10 ? "grey" : "transparent",
                                color: count === 10 ? "white" : "black",
                              }}
                              >
                            <h4
                             style={{
                                color: count === 10 ? "white" : "black",
                              }}
                            >$10</h4>
                            <h5>Online Access </h5>

                            <ul>    
                                <li 
                                 style={{
                                color: count === 10 ? "white" : "black",
                              }}
                                >✓  Live Stream Access</li>
                                <li 
                                 style={{
                                color: count === 10 ? "white" : "black",
                              }}
                                >✓  Online Q&A Session</li>
                                <li 
                                 style={{
                                color: count === 10 ? "white" : "black",
                              }}
                                >✓ Digital Materials</li>
                                <li 
                                 style={{
                                color: count === 10 ? "white" : "black",
                              }}
                                >✓ Session Recordings</li>

                            </ul>
                        </div>

                    </div>
                    <div className="Main--section--last--part1">

                        <h3>Why The Zalaquett Group?</h3>

                        <div className="Main--section--last--part1--box">

                            <ul>
                                <li> Over 50 successful international investors</li>
                                <li> Complete end-to-end investment solutions</li>
                                <li>Expert guidance in US real estate markets</li>
                                <li> Specialized in helping Latin American investors</li>

                            </ul>
                           
                        </div>

                    </div>


                </div>

                
             


            </div>


            
                
            <div className="Main--section--last--part1--button" onClick={gotonewpage}>
            Reserva Ahora {count ? `$${count}` : ''}

            </div>

        </div>

    
        </div>
    </div>

</div>
        </>
    )



}


export default Page1