import React, { useEffect, useState } from 'react';


import Footer from '../components/Header_Footer/Footer';

import Page1 from '../components/landing-page-componets/Page1_Spanish';


  

const E1 = () => {

 

  return (

    <>

    <Page1/>
    
    </>
  )

}

export default E1