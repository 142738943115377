import React, { useEffect, useState } from "react";

import Mountain from "../../img/Mountain.png";
import Visa from "../../img/Visa.png";
import MAsterCard from "../../img/MAsterCard.png";
import PayPal from "../../img/PayPal.svg 1.png";
import Amex from "../../img/Amex.png";
import location from "../../img/Zipcode.png";
import navigation from "../../img/navigation.png";
import Trash from "../../img/Trashicon.png";

import phone from "../../img/phone.png";
import creditcard from "../../img/creditcard.png";


import flag from "../../img/flag.png";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from "react-router-dom";



import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Page3_Spanish = () => {

  const stripe = useStripe();
  const elements = useElements();
  const [amount, setAmount] = useState(3000);
  const [amountshow, setAmountshow] = useState(3000);

  const [count, setCount] = useState(1);


  const [name, setName] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);



  const [sections, setSections] = useState([{ id: 1 }]);

  const navigate = useNavigate();
  // Function to handle adding a new section

  useEffect(() => {

    const savedPricing = localStorage.getItem('pricing');
    const newPricing = savedPricing * 100
    setAmount(newPricing)
    setAmountshow(newPricing)

  }, []);

  const addSection = () => {
    setSections([...sections, { id: sections.length + 1 }]);
    const alpha = sections.length + 1;
    const amounts2 = amount * alpha;
    setAmountshow(amounts2)
  };


  const removeSection = () => {
    if (sections.length > 1) {
      const newSections = sections.slice(0, -1);
      setSections(newSections);
      setAmountshow(amount * newSections.length);

    }
  };







  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) return;



    localStorage.setItem('ticketCount', sections.length);
    const amounts = amount * sections.length;
    setAmount(amounts)
    console.log('amount', amount);

    const { clientSecret } = await fetch('https://metaconversionbackend-production.up.railway.app/api/strippayment', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ amounts }),
    }).then((res) => res.json());

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (result.error) {
      setError(result.error.message);
    } else if (result.paymentIntent.status === 'succeeded') {
      navigate('/From4')
      setSuccess(true);
      setError(null);
    }
  };

  const [selectedLocation, setSelectedLocation] = useState(null);


  const handleLocationChange = (option) => {
    setSelectedLocation(option);
  };


  return (
    <>
      <div class="container">


        <div class="Main--section--form--Qualification">
          <div class="Main--container">

            <div class="Main--section--Confrence onlyforborder" >




              <h2>Regístrate para nuestra conferencia</h2>


              <hr />

              <div class="Confrence--Ticket">
                <h5>Información sobre Entradas</h5>
                <hr />

                <div class="Confrence--Ticket--info">

                  {sections.map((section, index) => (
                    <div key={section.id} className="info--navigation--hr">
                      <div className="Ticket--info--navigation">
                        <img src={navigation} alt="Navigation Icon" />
                        <p>|</p>
                        <input
                          type="text"
                          name={`navigation-${section.id}`}
                          placeholder="Example@gmail.com"
                        />
                      </div>

                      <div className="Ticket--info--number">
                        <img src={phone} alt="Phone Icon" />
                        <p>|</p>
                        {/* <img src={flag} alt="Flag Icon" />
            <input
              type="number"
              name={`number-${section.id}`}
              placeholder="54+ _ _ _ _  _ _ _   _ _ _"
            /> */}
                        <PhoneInput
                          country={"mx"} // Default country

                          // onChange={handlePhoneChange} // This updates the mobile number and country code
                          enableAreaCodes={true}
                          countryCodeEditable={false}
                          disableDropdown={false} // Allows selection of country code
                        />
                      </div>


                      {/* <div className="Ticket--info--zipcode">
                        <img src={location} alt="Location Icon" />
                        <p>|</p>
                        <input
                          type="text"
                          name={`zipcode-${section.id}`}
                          placeholder="Zip Code"
                        />
                      </div> */}

                    </div>
                  ))}


                </div>


                <div class="Confrence--Ticket--addmore">
                  <button onClick={addSection}> Agregar Más</button>

                  {sections.length > 1 && (

                    // <button onClick={removeSection} style={{ background: "#101720" }}> 
                    // </button>


                    <div className="remove" onClick={removeSection}>
                      <img src={Trash} alt="" style={{ width: "15%", cursor: "pointer" }} />
                    </div>
                  )}
                </div>



              </div>




            </div>



            <div class="heelo">
              {/* <img src={Mountain} alt="" />


              */}



<div class="Confrence--Ticket--type">
                <h5>Tipo de Boleto</h5>
                <hr />


                <div class="Ticket--type--checkbox">

                  <div class="Confrence--Ticket--type--Person">
                    <input type="checkbox"  
                      checked={selectedLocation === "InPerson"}
                      onChange={() => handleLocationChange("InPerson")} />
                    <p>Boleto Presencial</p>
                  </div>

                  <div class="Confrence--Ticket--type--Person">
                    <input type="checkbox" 
                      checked={selectedLocation === "OnlineTicket"}
                      onChange={() => handleLocationChange("OnlineTicket")} />
                    <p>Boleto en Línea</p>
                  </div>

                </div>
              </div>


              <div class="Confrence--Ticket--type">
                <h5>Método de Pago</h5>
                <hr />


                <div class="Confrence--Ticket--type--credit">
                  <img src={creditcard} alt="" />
                  <p>Credit Card</p>
                </div>

                <h5>Información de Crédito</h5>
                <hr />


                {/* <div class="Confrence--Ticket--type--card">
                    <input type="text" name="" id="" placeholder="Card number"/>
                    <input type="text" name="" id="" placeholder="Cardholder Name"/>
                </div>
                <div class="Confrence--Ticket--type--card--info">
                    <input type="text" name="" id="" placeholder="Expiration"/>
                    <input type="text" name="" id="" placeholder="CVC"/>
                </div> */}





                <form className="payment-form">

                  <div className="form-grid">
                    <div className="form-group">

                      <CardElement
                        options={{
                          style: {
                            base: {
                              fontSize: '16px',
                              color: '#424770',
                              '::placeholder': { color: '#aab7c4' },
                            },
                            invalid: { color: '#9e2146' },
                          },
                          hidePostalCode: true,
                        }}
                      />
                    </div>
                    <div className="form-group">

                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Cardholder Name"
                        required
                      />
                    </div>
                  </div>
                  {/* <button type="submit" disabled={!stripe}>
                        Pay
                    </button>
                    {error && <div className="error-message">{error}</div>}
                    {success && <div className="success-message">Payment succeeded!</div>} */}
                </form>



                <h5>Precio Total del Boleto</h5>
                <hr />


                <h3>${amountshow / 100}   USD</h3>
              </div>




              {/* <div className="Main--section--Qualification--button">
               Buy Ticket 
            </div> */}


              <button disabled={!stripe} className="Main--section--Qualification--button" onClick={handleSubmit}>
                Comprar Boleto
              </button>
              {error && <div className="error-message">{error}</div>}
              {success && <div className="success-message">Payment succeeded!</div>}


              <div class="Main--section--last--part1--currency">
                <p>Secured By:</p>

                <div className="icon-my-box">


                  <img src={Visa} alt="" />
                  <img src={MAsterCard} alt="" />
                  <img src={PayPal} alt="" />
                  <img src={Amex} alt="" />

                </div>
              </div>



            </div>


          </div>
        </div>




      </div>
    </>
  )



}


export default Page3_Spanish