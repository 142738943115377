import React, { useEffect, useState } from 'react';

import Page1 from '../components/landing-page-componets/Page1';
import Footer from '../components/Header_Footer/Footer';


  

const My1 = () => {

 

  return (

    <>

    <Page1/>
    
    </>
  )

}

export default My1