import React, { useState } from "react";

import Mountain from "../../img/Mountain.png";
import Visa from "../../img/Visa.png";
import MAsterCard from "../../img/MAsterCard.png";
import PayPal from "../../img/PayPal.svg 1.png";
import Amex from "../../img/Amex.png";
import { useNavigate } from "react-router-dom";

const Page2_Spanish = () => {

    const navigate = useNavigate();




    const gotonew = () => {
        navigate("/paymentprocess");
    }

    const [selectedOption, setSelectedOption] = useState(null);

    const handleCheckboxChange = (option) => {
        setSelectedOption(option);
    };


    const [selectedTimeframe, setSelectedTimeframe] = useState(null);


    const handleTimeframeChange = (option) => {
        setSelectedTimeframe(option);
    };


    const [selectedLocation, setSelectedLocation] = useState(null);


    const handleLocationChange = (option) => {
        setSelectedLocation(option);
    };
    return (
        <>
            <div className="container">


                <div className="Main--section--form--Qualification">
                    <div className="Main--container">

                        <div className="Main--section--Qualification">



                            <h2>Preguntas de calificación</h2>


                            <h6>¿Estás más interesado en invertir en propiedades en preconstrucción
                                o en comprar terrenos?</h6>



                            <div className="Qualification--check--main">


                                <div className="Qualification--checkbox">
                                    <input
                                        type="checkbox"
                                        checked={selectedLocation === "preconstructionMiami"}
                                        onChange={() => handleLocationChange("preconstructionMiami")}
                                    />
                                    <p>Preconstrucción en Miami</p>
                                </div>
                                <div className="Qualification--checkbox">
                                    <input
                                        type="checkbox"
                                        checked={selectedLocation === "landPurchaseColorado"}
                                        onChange={() => handleLocationChange("landPurchaseColorado")}
                                    />
                                    <p>Compra de terrenos en Colorado</p>
                                </div>

                            </div>

                            <h6>¿Cuál es tu presupuesto de inversión?</h6>
                            <div className="Qualification--check--main">
                                <div className="Qualification--checkbox">
                                    <input
                                        type="checkbox"
                                        checked={selectedOption === "lessThan100k"}
                                        onChange={() => handleCheckboxChange("lessThan100k")}
                                    />
                                    <p>Less than $100,000</p>
                                </div>

                                <div className="Qualification--checkbox">
                                    <input
                                        type="checkbox"
                                        checked={selectedOption === "100kTo500k"}
                                        onChange={() => handleCheckboxChange("100kTo500k")}
                                    />
                                    <p>$100,000 - $500,000</p>
                                </div>

                                <div className="Qualification--checkbox">
                                    <input
                                        type="checkbox"
                                        checked={selectedOption === "moreThan500k"}
                                        onChange={() => handleCheckboxChange("moreThan500k")}
                                    />
                                    <p>More than $500,000</p>
                                </div>
                            </div>

                            <h6>¿Estás buscando una inversión a corto o largo plazo?</h6>

                            <div className="Qualification--check--main">


                                <div className="Qualification--checkbox">
                                    <input
                                        type="checkbox"
                                        checked={selectedTimeframe === "shortTerm"}
                                        onChange={() => handleTimeframeChange("shortTerm")}
                                    />
                                    <p>Corto plazo (0-6 Meses  <span>A  </span> 1-3 años)</p>
                                </div>
                                <div className="Qualification--checkbox">
                                    <input
                                        type="checkbox"
                                        checked={selectedTimeframe === "longTerm"}
                                        onChange={() => handleTimeframeChange("longTerm")}
                                    />
                                    <p>Largo plazo (6 Meses <span>A</span>  más de 3 años)</p>
                                </div>

                            </div>


                            <div className="Main--section--Qualification--button-box">
                                <div className="Main--section--Qualification--button" onClick={gotonew}>
                                    Entregar
                                </div>
                            </div>


                        </div>



                        <div className="heelo">
                            <img src={Mountain} alt="" />


                            <div className="Main--section--last--part1--currency">
                                <p>Asegurado por:</p>

                                <div className="icon-my-box">


                                    <img src={Visa} alt="" />
                                    <img src={MAsterCard} alt="" />
                                    <img src={PayPal} alt="" />
                                    <img src={Amex} alt="" />

                                </div>
                            </div>

                        </div>


                    </div>
                </div>




            </div>
        </>
    )



}


export default Page2_Spanish