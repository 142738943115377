import axios from "axios";
import React, { useState } from "react";
import { baseUrl } from "../baseurl";


const ContactUs=()=>{
  const [formData, setFormData] = useState({
    EMAIL: "",
    FNAME: "",
    LNAME: "",
    PHONE: "",
    tags: "5457830", // Adding the hidden tag
  });

  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post(
  //       `${baseUrl}/subscribe`, // Replace with your server endpoint
  //       formData
  //     );
  //     setResponseMessage("Subscription successful!");
  //   } catch (error) {
  //     console.error("Error submitting the form", error);
  //     setResponseMessage("Subscription failed. Please try again.");
  //   }
  // };
     
  const handleSubmit = async (e) => {
    e.preventDefault();

    const mailchimpUrl = `https://ownapieceofcolorado.us8.list-manage.com/subscribe/post?u=343c895cb18c7bd146370ef82&id=e0c1940a12`;

    const form = new URLSearchParams(formData);

    try {
      const response = await fetch(mailchimpUrl, {
        method: "POST",
        body: form,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        mode: "no-cors", // This allows the request to bypass CORS restrictions
      });

      if (response.ok) {
        // setMessage("Subscription successful!");
      } else {
        // setMessage("Subscription failed. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      // setMessage("An error occurred while subscribing.");
    }
  };
  
  
  return(

 
<div style={{ maxWidth: "600px", margin: "0 auto", background: "#fff", padding: "20px" }}>
      <h2>Subscribe</h2>
      <p>
        <span style={{ color: "red" }}>*</span> indicates required
      </p>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="EMAIL">Email Address <span style={{ color: "red" }}>*</span></label>
          <input
            type="email"
            id="EMAIL"
            name="EMAIL"
            required
            value={formData.EMAIL}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="FNAME">First Name</label>
          <input
            type="text"
            id="FNAME"
            name="FNAME"
            value={formData.FNAME}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="LNAME">Last Name</label>
          <input
            type="text"
            id="LNAME"
            name="LNAME"
            value={formData.LNAME}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="PHONE">Phone Number</label>
          <input
            type="text"
            id="PHONE"
            name="PHONE"
            value={formData.PHONE}
            onChange={handleChange}
          />
        </div>
        <button type="submit">Subscribe</button>
      </form>
      {responseMessage && <p>{responseMessage}</p>}
    </div>


        )







}


export default ContactUs