import React, { useEffect, useState } from 'react';

import Header from '../components/Header_Footer/Header';

import Page4 from '../components/landing-page-componets/Page4';
import Footer from '../components/Header_Footer/Footer';
import Page4_Spanish from '../components/landing-page-componets/Page4_Spanish';


  

const E4 = () => {

 

  return (

    <>
    <Page4/>
    
    </>
  )

} 

export default E4