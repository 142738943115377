import React, { useEffect, useState } from 'react';

import Page3 from '../components/landing-page-componets/Page3';
import Footer from '../components/Header_Footer/Footer';
import Page3_Spanish from '../components/landing-page-componets/Page3_Spanish';


  

const My3 = () => {

 

  return (

    <>
    <Page3_Spanish/>
    

    </>
  )

}

export default My3