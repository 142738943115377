import React, { useEffect, useState } from "react";

import Mountain from "../../img/Exclude.png";
import Visa from "../../img/Visa.png";
import MAsterCard from "../../img/MAsterCard.png";
import PayPal from "../../img/PayPal.svg 1.png";
import Amex from "../../img/Amex.png";
import location from "../../img/location.png";
import navigation from "../../img/navigation.png";
import checkmark from "../../img/checkmark.png";

import phone from "../../img/phone.png";

import flag from "../../img/flag.png";

const Page4 = () => {


    const [count, setCount] = useState(1); 

    useEffect(() => {
 
        const savedPricing = localStorage.getItem('ticketCount');
        setCount(savedPricing)

      
        

  }, []);


  const handleClick = () => {
    // Open the link in a new tab
    const newTab = window.open('https://calendly.com/veronica-thezalaquettgroup', '_blank');

    // Set a timeout to navigate back to the root route after 1 second
    setTimeout(() => {
        // If you are using React Router for navigation
        // You can use the useHistory hook to programmatically navigate
        window.location.href = '/English'; // Navigate back to the root route
    }, 1000);

    // Optional: Focus on the new tab
    if (newTab) newTab.focus();
};



    return (
        <>
            <div class="container">


                <div class="Main--section--form--Qualification">
                    <div class="Main--container--bootstrap">


                        <div class="Main--section--Confrence">

                            <div class="Main--section--Confrence--last">



                                <img src={checkmark} alt="" />


                                <h2>{count}Tickets</h2>
                                <h2>Booked successfully</h2>


                                <p>Thank you for your payment. Your order is being processed and you will receive a confirmation email shortly.</p>
                            </div>


                            <div class="Main--section--Confrence--last--button">
                                <button onClick={handleClick}>Continue to Book Consultation</button>
                            </div>
                        </div>



                        <div class="heelo">
                            <img src={Mountain} alt="" />



                            <div className="Main--section--last--part1--currency">
                                <p>Secured By:</p>

                                <div className="icon-my-box">


                                    <img src={Visa} alt="" />
                                    <img src={MAsterCard} alt="" />
                                    <img src={PayPal} alt="" />
                                    <img src={Amex} alt="" />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </>
    )



}


export default Page4