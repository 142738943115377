import React, { useState } from "react";

import Mountain from "../../img/Mountain.png";
import Visa from "../../img/Visa.png";
import MAsterCard from "../../img/MAsterCard.png";
import PayPal from "../../img/PayPal.svg 1.png";
import Amex from "../../img/Amex.png";
import { useNavigate } from "react-router-dom";

const Page2 = () => {



    const navigate = useNavigate();




    const gotonew = () => {
        navigate("/engpaymentprocess");
    }


    const [selectedOption, setSelectedOption] = useState(null);

    const handleCheckboxChange = (option) => {
      setSelectedOption(option);
    };


    const [selectedTimeframe, setSelectedTimeframe] = useState(null);


    const handleTimeframeChange = (option) => {
        setSelectedTimeframe(option);
    };


    const [selectedLocation, setSelectedLocation] = useState(null);


    const handleLocationChange = (option) => {
        setSelectedLocation(option);
    };


    return (
        <>
    <div className="container">


<div className="Main--section--form--Qualification">
    <div className="Main--container">

        <div className="Main--section--Qualification">
            


            <h2>Qualification Questions</h2>
        

            <h6>Are you more interested in investing in pre-constructionproperties or purchasing land?</h6>


            
            <div className="Qualification--check--main">


            <div className="Qualification--checkbox">
                <input type="checkbox" 
                checked={selectedLocation === "preconstructionMiami"}
                onChange={() => handleLocationChange("preconstructionMiami")}
                />
                <p>Pre-construction in Miami</p>
            </div>
            <div className="Qualification--checkbox">
                <input type="checkbox" 
                 checked={selectedLocation === "landPurchaseColorado"}
                 onChange={() => handleLocationChange("landPurchaseColorado")}
                 />
                <p>Land purchases in Colorado</p>
            </div>

        </div>

        <h6>What is your investment budget?</h6>
        <div className="Qualification--check--main">
      <div className="Qualification--checkbox">
        <input
          type="checkbox"
          checked={selectedOption === "lessThan100k"}
          onChange={() => handleCheckboxChange("lessThan100k")}
        />
        <p>Less than $100,000</p>
      </div>

      <div className="Qualification--checkbox">
        <input
          type="checkbox"
          checked={selectedOption === "100kTo500k"}
          onChange={() => handleCheckboxChange("100kTo500k")}
        />
        <p>$100,000 - $500,000</p>
      </div>

      <div className="Qualification--checkbox">
        <input
          type="checkbox"
          checked={selectedOption === "moreThan500k"}
          onChange={() => handleCheckboxChange("moreThan500k")}
        />
        <p>More than $500,000</p>
      </div>
    </div>

        <h6>Are you looking for a short-term or long-term investment?</h6>

        <div className="Qualification--check--main">


            <div className="Qualification--checkbox">
                <input type="checkbox" 
                  checked={selectedTimeframe === "shortTerm"}
                  onChange={() => handleTimeframeChange("shortTerm")}
                   />
                <p>Short-term (0-6 Months <span>To </span> 1-3 years)</p>
            </div>
            <div className="Qualification--checkbox">
                <input type="checkbox" 
                 checked={selectedTimeframe === "longTerm"}
                 onChange={() => handleTimeframeChange("longTerm")}
                 />
                <p>Long-term (6 Months <span>To</span> more than 3 years)</p>
            </div>

        </div>


<div className="Main--section--Qualification--button-box">
<div className="Main--section--Qualification--button" onClick={gotonew}>
            Submit
        </div>
</div>
        

        </div>



        <div className="heelo">
        <img src={Mountain} alt=""/>
        
            
        <div className="Main--section--last--part1--currency">
            <p>Secured By:</p>

            <div className="icon-my-box">

      
            <img src={Visa} alt=""/>
            <img src={MAsterCard}alt=""/>
            <img src={PayPal} alt=""/>
            <img src={Amex} alt=""/>

            </div>
        </div>

    </div>


    </div>
</div>




</div>
        </>
    )



}


export default Page2